import React from "react"
import SEO from "../../components/seo"
// import Layout from '../layouts/findep'
import LayoutsComponent from '../../layouts/LayoutsComponent';
import TerminosTradicional from '../../components/pages/footer/TerminosTradicional'

const TerminosTradicionalPage = ({ pageContext, location }) => (
  <LayoutsComponent
    broker={pageContext.broker}
    layout={pageContext.layout}
    companyName={pageContext.company}
    pageContext={pageContext}
    location={location}
    render={() => (
      <>
        <SEO title={pageContext.title} />
        <TerminosTradicional companyName={pageContext.company} />
      </>
    )}
  />
)

export default TerminosTradicionalPage